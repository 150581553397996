import React, { Component } from "react";
import { Link } from 'gatsby';

import { ArrowButton } from "../buttons/Arrow_Link.js";

export default class Desktop_Navigation_Panel extends Component {
	constructor(props){
		super(props);
		this.state = {
			active: false,
			sub_menu_shown: false
		};
	}
	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if (this.props.sub_menu_shown !== prevProps.sub_menu_shown) {
			this.setState({
				sub_menu_shown: this.props.sub_menu_shown
			})
		}
	}
	hovered_parent_link(){}
	unhovered_parent_link(){}
	render(){
		return <div className="dropdown-content">
			<div className="content grid">
				<div className="social-icons push-d-1 grid-d-4">
					<h3 className="styling-eyebrow">Follow</h3>
					<ul>
						<li><a href="https://www.instagram.com/thecharlesnyc/" title="Ig" target="_blank" rel="noopener noreferrer"><span>Ig</span></a></li>
						<li><a href="https://twitter.com/thecharlesnyc" title="Tw" target="_blank" rel="noopener noreferrer"><span>Tw</span></a></li>
						<li><a href="https://www.facebook.com/thecharlesnyc" title="Fb" target="_blank" rel="noopener noreferrer"><span>Fb</span></a></li>
						<li><a href="https://www.pinterest.com/thecharlesnyc/" title="Pi" target="_blank" rel="noopener noreferrer"><span>Pi</span></a></li>
					</ul>
				</div>
				<div className="image-section grid-d-5">
					<Link to="/contact" >
						<div className="image lazyload-wrapper">
							<img alt="telephones" src="https://api.thecharlesnyc.com/wp-content/uploads/sites/5/2019/12/Screen_Shot_2019-11-01_at_5.11.49_PM-1.png" loading="lazy" style={{position:"absolute", top:"0", width:"100%", objectFit:"cover"}}/>
						</div>
						<ArrowButton title="Contact Us" />
					</Link>
				</div>
				<div className="image-section grid-d-5">
					<Link to="/contact/new-business" >
						<div className="image lazyload-wrapper">
							<img alt="office space" src="https://api.thecharlesnyc.com/wp-content/uploads/sites/5/2019/12/Screen_Shot_2019-11-01_at_5.21.49_PM-1.png" loading="lazy" style={{position: "absolute", top:"0", width:"100%", objectFit:"cover"}}/>
						</div>
						<ArrowButton title="New Business" />
					</Link>
				</div>
			</div>
		</div>;
	}
}
